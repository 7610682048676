.side-panel-item {
  font-size: 1.125em;
  height: 2em;
  width: calc(100% - 1em - 0.4em);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: .25em .5em;
  margin: .1em .2em;
  position: relative;
  border-radius: 10px;
  user-select: none;

  &.active {
    background-color: var(--color-quaternary);

    .icon {
      color: var(--violet-blue);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.175em;
      border-radius: 10px;
      height: 100%;
      background-color: var(--violet-blue);
      transition: .25s ease-in-out;
    }
  }

  .side-panel-item-icon {
    width: 1em;

    svg {
      .fa-primary {
        fill: var(--color-primary);
      }

        .fa-secondary {
            fill: var(--color-tertiary);
        }
    }
  }
}