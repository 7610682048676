.side-panel {
  width: fit-content;
  height: calc(100vh - 2em);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2em;
  padding: 1em 1em;
  background: var(--white);
  border-right: 2px solid var(--color-tertiary);
}