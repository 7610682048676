form {
  font-size: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  gap: 1.75em;

  .input-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;

    .input-label {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.5em;

      .input-label-text {
        font-size: 1em;
        color: var(--color-primary);
        position: relative;

        &.required {
          &:after {
            content: '*';
            color: var(--color-tertiary);
            position: absolute;
            left: 105%;
            top: 0;
          }
        }
      }

      .input-label-password {
        font-size: 1em;
        color: var(--color-primary);
        position: relative;

        &.required {
          &:after {
            content: '*';
            color: var(--color-tertiary);
            position: absolute;
            left: 105%;
            top: 0;
          }
        }
      }

      .input {
        color: var(--color-primary);
        background: var(--color-background);
        border: none;
        border-bottom: 2px solid var(--color-tertiary);
        resize: vertical;
        width: 100%;

        &:focus {
          outline: none;
        }
      }

      .password-row {
        width: 100%;

        .input-password {
          color: var(--color-primary);
          background: var(--color-background);
          border: none;
          border-bottom: 2px solid var(--color-tertiary);
          resize: vertical;
          width: 100%;

          &:focus {
            outline: none;
          }
        }

        .password-visibility-toggle {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: var(--color-primary);
          background: var(--color-background);
          border: none;
          border-bottom: 2px solid var(--color-tertiary);
          resize: vertical;
          width: fit-content;
          position: relative;

          &:focus {
            outline: none;
          }

          .password-visibility-icon {
            width: 1.25em;
            position: absolute;
            transform: translate(0, -25%);

            .fa-primary {
              fill: var(--color-primary);
            }

            .fa-secondary {
              fill: var(--color-tertiary);
            }
          }
        }
      }

    }

    .submit-form-button {
      width: 100%;
    }
  }
}

.form-error-container {
  padding: 1em;
  width: 100%;

  .form-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-red-50);
    width: 100%;
    padding: 2em 0;
    border: 2px solid var(--color-red);
    border-radius: 0.5em;
    color: var(--color-white);
    width: 100%;
  }
}