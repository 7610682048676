.color-theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1em;
  right: 1em;
  width: 3em;
  height: 3em;
  background-color: var(--color-tertiary);
  border-radius: 50%;

  .color-theme-switcher-icon {
    width: 1.5em;
    padding: .5em;

    &#moon {
      .fa-primary {
        fill: var(--color-primary);
      }

      .fa-secondary {
        fill: var(--color-background);
      }
    }

    &#sun {
      .fa-primary {
        fill: var(--color-background);
      }

      .fa-secondary {
        fill: var(--color-primary);
      }
    }
  }
}