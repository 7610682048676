section.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;

  .login-form {
    width: 50%;
  }

  .form-error-container {
    width: 50%;
  }
}