.courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .courses-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    overflow-y: scroll;

    .course {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--color-quaternary);
      padding: 1em;
      border-radius: 1em;
      position: relative;
      padding-top: 2em;

      .course-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: .75em 0;
        img {
          border-radius: 1em;
        }
      }

      .course-details {
        padding: .75em 0;
        width: 100%;
        justify-content: space-between;
      }

      .course-actions-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 2em;
        background-color: var(--color-background);
        border-radius: 1em;
        gap: 1em;
        padding: .5em 0;

        .course-actions-toggle {
          svg  {
            width: .75em;

            .fa-primary {
              fill: var(--color-primary);
            }

            .fa-secondary {
              fill: var(--color-tertiary);
            }
          }
        }

        .course-actions-list {
          .course-action {
            svg  {
              width: .75em;

              .fa-primary {
                fill: var(--color-primary);
              }

              .fa-secondary {
                fill: var(--color-tertiary);
              }
            }
          }
        }
      }
    }
  }

  .courses-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1em;
    right: 5em;
    width: 3em;
    height: 3em;
    background-color: var(--color-tertiary);
    border-radius: 50%;

    .course-add-button-icon {
      width: 1.5em;
      padding: .5em;
      .fa-primary {
        fill: var(--color-primary);
      }

      .fa-secondary {
        fill: var(--color-background);
      }
    }
  }
}

.courses-popup-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .create-course-form {
    width: 80%;
  }
}