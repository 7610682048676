.global-container {
  position: relative;

  .admin-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    .panel-title {
      font-size: 1.5em;
      font-weight: 600;
      color: var(--violet-blue);
      background: var(--white);
    }
  }
}