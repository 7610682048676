.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation : background 0.5s ease-in-out;

  @keyframes background {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .modal-content {
    position: relative;
    width: 80%;
    height: 90%;
    background-color: var(--color-background);
    border-radius: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;

    animation: expansive 0.25s ease-in-out;

    @keyframes expansive {
      from {
        transform: scale(0.5);
      }
      to {
        transform: scale(1);
      }
    }

    .modal-header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 1em 1em;
      border-bottom: 1px solid var(--color-light-grey);

      h3 {
        font-size: 1.5em;
        font-weight: 700;
        margin: 0;
      }

      .modal-header-close {
        position: absolute;
        right: 1em;
        cursor: pointer;
        font-size: 1.5em;
        color: var(--color-light-grey);
        transition: .25s color ease-in-out;

        &:hover {
          color: var(--color-red);
          transition: .25s color ease-in-out;
        }
      }
    }
  }
}