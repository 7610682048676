// Normalize
@import './normalize.scss';

// Import fonts
//@import '../fonts/fonts.scss';

// Variables
html {
  //  Colors
  --color-background: #f7f3f3;
  --color-primary: #111827;
  --color-secondary: #898989;
  --color-tertiary: #2563EB;
  --color-quaternary: #E3E8ED;
  --color-lightText: #17213566;
  --color-white: #E3E8ED;
  --color-red: #DC2626;
  --color-red-50: rgba(220, 38, 38, 0.5);
}

// Global styles

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 22px;
  background-color: var(--color-background);
  color: var(--color-primary);
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  transition: background-color 0.3s, color 0.3s;

  .light-text {
    color: var(--color-lightText);
  }

  .color-primary {
    color: var(--color-primary);
  }

  .color-secondary {
    color: var(--color-secondary);
  }

  .color-tertiary {
    color: var(--color-tertiary);
  }

  .color-quaternary {
    color: var(--color-quaternary);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Code Pro Bold', sans-serif;
    color: var(--color-primary);
  }

  h1 {
    font-size: 3.5em;
  }

  h2 {
    font-size: 2.5em;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.25em;
  }

  h6 {
    font-size: 1em;
  }

  p {
    font-size: 1.25em;
    line-height: 1.5;
    margin: 0;
  }

  .formatted {
    font-family: 'Open Sans Bold', sans-serif;
    color: var(--color-secondary);
    font-size: 1.5em;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: var(--color-primary);
    position: relative;
    cursor: pointer;
  }

  a.underline-anim {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0;
      height: 2px;
      background-color: var(--color-tertiary);
      transition: all .3s;
    }

    &:hover:after {
      display: block;
      width: 100%;
      left: 0;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  dialog {
    font-size: 1vw;

    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }

    .modal-content {
      position: relative;
      background: var(--color-background);
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .modal-closer {
        position: absolute;
        top: 1em;
        right: 1em;
        @media screen and (max-width: 768px) {
          top: 1em;
          right: 1em;
        }
      }
    }
  }

  span.light-text {
    color: var(--color-lightText);
  }

  section {
    margin: 6.25em 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }

  .icon-button {
    width: 2em;
    display: flex;
    color: var(--color-primary);
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    .button-image {
      width: 100%;
    }
  }

  .primary-button {
    color: var(--color-white);
    background-color: var(--color-tertiary);
    border: 2px solid transparent;
    border-radius: .45em;
    padding: 0.75em 1.5em;
    transition: all .3s;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      border: 2px solid var(--color-tertiary);
      color: var(--color-tertiary);
      transition: all .3s;
    }
  }
}